// @ts-nocheck
import { Heading, Flex, Text, Skeleton, ChartIcon, CommunityIcon, SwapIcon, Button } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter } from 'components/NextLink'
import Image from 'next/image'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { formatLocalisedCompactNumber } from 'utils/formatBalance'
import useSWRImmutable from 'swr/immutable'
import IconCard, { IconCardData } from '../IconCard'
import StatCardContent from './StatCardContent'
import GradientLogo from '../GradientLogoSvg'
import { useWeb3React } from '@pancakeswap/wagmi'



const Stats = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { account } = useWeb3React()

  const { data: tvl } = useSWRImmutable('tvl')
  const { data: txCount } = useSWRImmutable('totalTx30Days')
  const { data: addressCount } = useSWRImmutable('addressCount30Days')
  const trades = formatLocalisedCompactNumber(txCount)
  const users = formatLocalisedCompactNumber(addressCount)
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const tvlText = t('Examine these Traders call track records to see how their calls have performed historically.', { tvl: tvlString })
  const [entrusting, inFunds] = tvlText.split(tvlString)

  const UsersCardData: IconCardData = {
    icon: <CommunityIcon color="secondary" width="36px" />,
  }

  const TradesCardData: IconCardData = {
    icon: <SwapIcon color="primary" width="36px" />,
  }

  const StakedCardData: IconCardData = {
    icon: <ChartIcon color="failure" width="36px" />,
  }

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <Heading textAlign="center" scale="xl">
        {t('Who makes good calls?')}
      </Heading>

      <Text textAlign="center" color="textSubtle">
        {t('COPE Leaderboards can assist you in answering this question. Its sophisticated technologies perform the heavy lifting for you by providing access to current reports that are generated from tracking, storing, and analyzing calls made by top traders. This enables you to effortlessly gain valuable insights without the need for manual effort. ')}
      </Text>



      <NextLinkFromReactRouter to="/token-trader-leaderboard-faqs">
        <Button borderColor="blue!" variant={!account ? 'secondary' : 'primary'}>
          <Text color="white" bold fontSize="12px" mr="4px">
            {t('Read about how calls are classified and ranked')}
          </Text>
        </Button>
      </NextLinkFromReactRouter>



      <Flex flexDirection={['column', null, null, 'row']}>
        <IconCard {...UsersCardData} mr={[null, null, null, '15px']} mb={['15px', null, null, '0']}>
          <StatCardContent
            headingText={t("Track trader's current status", { users })}
            bodyText={t('View calls traders have made in the last 24 hrs.')}
            highlightColor={theme.colors.secondary}
          />
          <br/>
            <br/>

          <Image src={'/images/status.png'} alt="status" width={402} height={92 + 32}  />
        </IconCard>
        <IconCard {...TradesCardData} mr={[null, null, null, '15px']} mb={['15px', null, null, '0']}>
          <StatCardContent
            headingText={t('Lookup historical calls', { trades })}
            bodyText={t('Scan historical calls made by popular traders')}
            highlightColor={theme.colors.primary}
          />
            <br/>
              <br/>



          <Image src={'/images/historical.png'} alt="status" width={402} height={92 + 32}  />
        </IconCard>



        <IconCard {...StakedCardData} mr={[null, null, null, '15px']} mb={['15px', null, null, '0']}>
          <StatCardContent
            headingText={t('Analyze trader call accuracy', { tvl: tvlString })}
            bodyText={t("View detailed reports on a trader's current and historical call accuracy.")}
            highlightColor={theme.colors.failure}
          />
            <br/>


            <Image src={'/images/insights.png'} alt="insights" width={402} height={92 + 32}  />
        </IconCard>
      </Flex>
    </Flex>
  )
}

export default Stats
