// @ts-nocheck
import styled from 'styled-components'
import { useState,useEffect, useMemo } from 'react'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { Flex, Text, TicketFillIcon, PredictionsIcon, Button, ArrowForwardIcon, Row, Box, Alert } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import Red from "./resources/images/red-heart.png";
import Blue from "./resources/images/blue-heart.jpeg";
import Green from "./resources/images/green-heart.jpeg";
import ColoredWordHeading from '../ColoredWordHeading'
import IconCard, { IconCardData } from '../IconCard'
import PredictionCardContent from './PredictionCardContent'
import PredictionCardContent2 from './PredictionCardContent2'
import PredictionCardContent3 from './PredictionCardContent3'
import LotteryCardContent from './LotteryCardContent'
import CompositeImage from '../CompositeImage'
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import axios from "axios";
import { signAndConfirm, signAndConfirmTransaction,signAndConfirmBoth } from "./utility/common";
import { ReactSession } from "react-client-session";
import { DEFAULT_TIMEOUT } from "./connection";
import Home from "./Home";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { clusterApiUrl } from "@solana/web3.js";

import { createTheme, ThemeProvider } from "@mui/material";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import MintMain from "./MintMain";
import { MultiMintButton } from "./MultiMintButton";

import * as  anchor  from "@project-serum/anchor";

import { rpcHost, candyMachineId, network } from "./config";








const StyledLink = styled(NextLinkFromReactRouter)`
  width: 100%;
`

const TransparentFrame = styled.div<{ isDark: boolean }>`
  background: ${({ theme }) => (theme.isDark ? 'rgba(8, 6, 11, 0.6)' : ' rgba(255, 255, 255, 0.6)')};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  box-sizing: border-box;
  backdrop-filter: blur(12px);
  border-radius: 72px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 40px;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

const BottomLeftImgWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  bottom: -64px;
  max-width: 192px;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`

const TopRightImgWrapper = styled(Flex)`
  position: absolute;
  right: 0;
  top: -64px;

  max-width: 192px;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`





const PredictionCardData: IconCardData = {
  icon: <PredictionsIcon width="36px" color="inverseContrast" />,
  background: ' linear-gradient(180deg, #7645D9 0%, #5121B1 100%);',
  borderColor: '#313638',
  rotation: '-2.36deg',
}


const PredictionCardData2: IconCardData = {
  icon: <PredictionsIcon width="36px" color="inverseContrast" />,
  background: 'linear-gradient(180deg, #EF6461 0%, #EA8C62 51.17%, #E7A063 100%);',
  borderColor: '#313638',
  rotation: '-2.36deg',
}




const PredictionCardData3: IconCardData = {
  icon: <PredictionsIcon width="36px" color="inverseContrast" />,
  background: 'linear-gradient(180deg, #ffb237 0%, #ffcd51 51.17%, #ffe76a 100%);',
  borderColor: '#ffb237',
  rotation: '-2.36deg',
}











const LotteryCardData: IconCardData = {
  icon: <TicketFillIcon color="white" width="36px" />,
  background: ' linear-gradient(180deg, #7645D9 0%, #5121B1 100%);',
  borderColor: '#3C1786',
  rotation: '1.43deg',
}

const bottomLeftImage = {
  path: '/images/home/prediction-cards/',
  attributes: [
    { src: 'bottom-left', alt: 'CAKE card' },
    { src: 'green', alt: 'Green CAKE card with up arrow' },
    { src: 'red', alt: 'Red Cake card with down arrow' },
    { src: 'top-right', alt: 'CAKE card' },
  ],
}

const topRightImage = {
  path: '/images/home/lottery-balls/',
  attributes: [
    { src: '2', alt: 'Lottery ball number 2' },
    { src: '4', alt: 'Lottery ball number 4' },
    { src: '6', alt: 'Lottery ball number 6' },
    { src: '7', alt: 'Lottery ball number 7' },
    { src: '9', alt: 'Lottery ball number 9' },
  ],
}

const WinSection = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()


  return (
    <>


      <TransparentFrame isDark={theme.isDark}>
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Text fontSize="2em" textAlign="center" bold>{t('How can I access Leaderboard Features?')}</Text>
            <br/>
          <ColoredWordHeading textAlign="center" text={t('Buy a COPE Card Key to unlock features now')} />
          <Text color="textSubtle">{t('Please read the FAQ & disclaimer page before proceeding')}</Text>
          <Text mb="40px" color="textSubtle" />
          <Flex m="500 auto" flexDirection={['column', null, null, 'row']} maxWidth="1500px">


            <Flex
              flex="1"
              maxWidth={['275px', null, null, '100%']}
              mr={[null, null, null, '15px']}
              mb={['32px', null, null, '0']}
            >
              <IconCard {...PredictionCardData2}>
                <PredictionCardContent2 />
              </IconCard>

            </Flex>
            <Flex
              flex="1"
              maxWidth={['275px', null, null, '100%']}
              mr={[null, null, null, '-5px']}
              mb={['32px', null, null, '0']}
            >
              <IconCard {...PredictionCardData3}>
                <PredictionCardContent3 />
              </IconCard>
            </Flex>


          </Flex>
        </Flex>
      </TransparentFrame>
    </>
  )
}

export default WinSection
