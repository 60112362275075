import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Trade anything. No registration, no hassle.'),
  bodyText: t('Trade any token on BNB Smart Chain in seconds, just by connecting your wallet.'),
  reverse: true,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/trade/',
    attributes: [
      { src: 'BNB', alt: t('BNB token') },
      { src: 'BTC', alt: t('BTC token') },
      { src: 'CAKE', alt: t('CAKE token') },
    ],
  },
})

export const pnlSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Presenting the Alluring Shareable PnL Cards'),
  bodyText: t("Unlock the power to create your own captivating Shareable PnL Cards, each telling a unique tale of a trader's market prediction journey. With just a few clicks, generate a stunning card featuring their Twitter handle, the bold buy or sell call, the dynamic ROI, and the intriguing dance of entry and current prices. Revel in the excitement of sharing these mesmerizing cards, celebrating and connecting with fellow traders in the exhilarating realm of market predictions."),
  reverse: true,
  primaryButton: {
    to: '/leaderboard',
    text: t('Generate'),
    external: false,
  },
  secondaryButton: {
    to: '/faqs/leaderboard',
    text: t(''),
    external: false,
  },
  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: 'pnlcard', alt: t('') },

    ],
  },
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Introducing COPE Chat'),
  bodyText: t(
    'Engage in fascinating conversations with your beloved Trader AIs and uncover the brilliance behind their thoughts. Immerse yourself in an elegant and stimulating environment where wit and wisdom are served with a dash of fun. Welcome to a world of concise yet captivating AI interactions, tailored to enthrall every trading aficionado. Delve into the extraordinary and unravel the secrets of the trading realm with AI Trader Chats!',
  ),
  reverse: true,
  primaryButton: {
    to: '/chat',
    text: t('COPE Chat'),
    external: false,
  },
  secondaryButton: {
    to: '/chat/faqs',
    text: t(''),
    external: true,
  },

  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: 'bottom-right', alt: t('Small 3d pancake') },
      { src: 'top-right', alt: t('Small 3d pancake') },
      { src: 'chat', alt: t('CAKE token') },
      { src: 'top-left', alt: t('Small 3d pancake') },
    ],
  },
})
