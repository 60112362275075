//@ts-nocheck

import { useEffect, memo, useState } from 'react'
import { useModal, Modal, Button, Flex, Text } from '@pancakeswap/uikit'
import { useUser, useLogin, useLogout } from "@thirdweb-dev/react/solana";
import type { NextPage } from "next";
import DisclaimerModal from 'components/DisclaimerModal'
import { useUserLimitOrderAcceptedWarning } from 'state/user/hooks'

import { useTranslation } from '@pancakeswap/localization'

import { useCookies } from 'react-cookie';


function LoginDialog2() {
  const { t } = useTranslation()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [secret, setSecret] = useState();
  const login = useLogin();
  const logout = useLogout();
  const { user } = useUser();

  const [cookies, setCookie] = useCookies(['thirdweb_auth_token']);



  console.log('here is isLoggedIn')
  console.log(isLoggedIn)

  const getSecret = async () => {
    const res = await fetch("https://thirdweb-auth-solana-next.vercel.app/api/secret",
        {
          method: 'GET',
          credentials: 'include'
        }
    );
    console.log(res)
    console.log('above is the res')
    const data = await res.json();
    setSecret(data);
  };

  useEffect(() => {
    if (user) {
      setIsLoggedIn(true)
    }

    console.log(cookies.thirdweb_auth_token); // Outputs the value of "myCookie" cookie


    // getSecret()


  }, [user])



  const [onNotLoggedIn, onDismiss] = useModal(
    <Modal
      onDismiss={onDismiss}
      id="disclaimer-limit-order"

    >


    <Flex pb="24px" flexDirection="column">
      <Text bold textTransform="uppercase" fontSize="18px" color="secondary" mb="24px">
        {t('To access site upgrades, you must log in to the platform with your COPE pass. If you do not have a COPE pass, you can purchase one below using COPE.')}
        {secret}
      </Text>

  <Button onClick={() => login()}> LOGIN </Button>


    </Flex>





    </Modal>
  )

  useEffect(() => {
    if (!isLoggedIn) {
      onNotLoggedIn()
    }

    return () => {
      onDismiss()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  return null
}

export default memo(LoginDialog2)
